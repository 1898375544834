import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, AppBar, Toolbar, Typography, makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';


// Styles
const useStyles = makeStyles({
    title: {
        flexGrow: 1,
    },
    logo: {
        width: '50px',
        height: '50px',
        marginRight: '20px'
    },
    tab: {
        marginLeft: '10px',
        marginRight: '10px',
        cursor: 'pointer',
        '&:hover': {
            color: '#f7f7f7',
        }
    },
    selectedTab: {
        marginLeft: '10px',
        marginRight: '10px',
        borderBottom: '3px solid white',
        cursor: 'pointer',
    }
});

// Constants
const TABS = [
    { name: 'Dashboard', route: '/dashboard', icon: <DashboardIcon /> },
    { name: 'Manage Users', route: '/users', icon: <PeopleIcon /> },
];


const ADMIN_ROLES = ['systemAdmin'];

function mapUserRole(userRole) {
    switch (userRole) {
        case 'admin':
            return 'Receiver';
        case 'user':
            return 'Dispatcher';
        case 'systemAdmin':
            return 'Admin';
        default:
            return '';
    }
}


function Header() {
    const classes = useStyles();
    const navigate = useNavigate();
    const { isAuthenticated, setIsAuthenticated } = useAuth();
    const [selectedTab, setSelectedTab] = useState(TABS[0].name);
    const [errorMessage, setErrorMessage] = useState(null);
    const userRole = localStorage.getItem('userRole');

    function navigateTo(tab) {
        navigate(tab.route);
        setSelectedTab(tab.name);
    }
    async function handleLogout() {
        try {
            await axios.get(process.env.REACT_APP_AUTH_LOGOUT_ENDPOINT, { withCredentials: true });
            localStorage.removeItem('userRole');
            setIsAuthenticated(false);
        } catch (error) {
            console.error('Error during logout:', error);
            setErrorMessage("There was a problem logging out. Please try again.");
        }
    }
        const userRoleLabel = mapUserRole(userRole);  // Create this variable

    return (
        <>
            <nav>{errorMessage}</nav>
            <AppBar position='static'>
                <Toolbar>
                    <img src='https://airats.com/data/logo/ats-technical-logo-white.png' alt="Company Logo" className={classes.logo} />
                    <Typography variant="h6" className={classes.title}>
                        ATS Images Portal
                    </Typography>
                                        {isAuthenticated && (
                        <Typography variant="subtitle1">
                            Logged in as: {userRoleLabel}
                        </Typography>
                    )}
{isAuthenticated && ADMIN_ROLES.includes(userRole) && (
    <>
        {TABS.map(tab => (
            <Typography
                key={tab.name}
                variant="body1"
                className={selectedTab === tab.name ? classes.selectedTab : classes.tab}
                onClick={() => navigateTo(tab)}
                aria-current={selectedTab === tab.name ? "page" : undefined}
            >
                {tab.icon} {tab.name}
            </Typography>
        ))}
    </>
)}

                    {isAuthenticated && <Button color="inherit" onClick={handleLogout}>Logout</Button>}
                </Toolbar>
            </AppBar>
        </>
    );
}

export default Header;
