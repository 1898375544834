import React, { createContext, useReducer, useContext, useEffect } from 'react';
import axios from 'axios';

const SparePartsStateContext = createContext();
const SparePartsDispatchContext = createContext();

const sparePartsReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_START':
      return { ...state, isLoading: true, error: null };
    case 'FETCH_SUCCESS':
      return { ...state, allParts: action.payload, isLoading: false };
    case 'FETCH_FAILURE':
      return { ...state, isLoading: false, error: action.payload };
    case 'SET_DISPLAY_PARTS':
      return { ...state, displayParts: action.payload };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const SparePartsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(sparePartsReducer, {
    allParts: [],
    displayParts: [],
    isLoading: false,
    error: null,
  });

  useEffect(() => {
    const fetchAllSpareParts = async () => {
      dispatch({ type: 'FETCH_START' });
      try {
        const endpoint = `${process.env.REACT_APP_SPARE_PARTS_GET_ALL_ENDPOINT}`;
        const response = await axios.get(endpoint, { withCredentials: true });
        dispatch({ type: 'FETCH_SUCCESS', payload: response.data.data });
      } catch (error) {
        console.error("Error fetching all spare parts:", error);
        dispatch({ type: 'FETCH_FAILURE', payload: error.message });
      }
    };
    
    fetchAllSpareParts();
  }, [dispatch]);

  return (
    <SparePartsStateContext.Provider value={state}>
      <SparePartsDispatchContext.Provider value={dispatch}>
        {children}
      </SparePartsDispatchContext.Provider>
    </SparePartsStateContext.Provider>
  );
};

export const useSparePartsState = () => {
  const context = useContext(SparePartsStateContext);
  if (context === undefined) {
    throw new Error('useSparePartsState must be used within a SparePartsProvider');
  }
  return context;
};

export const useSparePartsDispatch = () => {
  const context = useContext(SparePartsDispatchContext);
  if (context === undefined) {
    throw new Error('useSparePartsDispatch must be used within a SparePartsProvider');
  }
  return context;
};
