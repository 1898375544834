import React, { useState, useEffect } from 'react';
import axios from 'axios';

function UserForm({ mode, userId, onUserUpdated, onUserCreated }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('user');
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (mode === 'update' && userId) {
            setIsUpdateMode(true);
            // TODO: Fetch user details logic if needed
                        const fetchUserDetails = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_USER_DETAIL_ENDPOINT}/${userId}`);
                    const user = response.data;
                    setUsername(user.username);
//                    setPassword(user.password); // Ideally, passwords should not be fetched or displayed. This is just for demonstration.
                    setRole(user.role);
                } catch (error) {
                    console.error("Error fetching user details:", error);
                }
            };
                        fetchUserDetails();  // Call the function to fetch user details
        } else {
            setIsUpdateMode(false);
        }
    }, [mode, userId]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage(null);
    console.log(`Submitting form with - Username: ${username}, Role: ${role}`);
        try {
            if (isUpdateMode) {
                await axios.put(`${process.env.REACT_APP_AUTH_UPDATE_USER_ENDPOINT}/${userId}`, { username, password, role }, { withCredentials: true });
                onUserUpdated();
            } else {
                await axios.post(process.env.REACT_APP_AUTH_SIGNUP_ENDPOINT, { username, password, role }, { withCredentials: true });
                onUserCreated();
                // Reset form fields after successful user creation
                setUsername('');
                setPassword('');
                setRole('');
            }
                    console.log('API call successful');
        } catch (error) {
            setErrorMessage("There was an error processing the request.");
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <label>
                Username:
                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required readOnly={isUpdateMode} />
            </label>


            <br />
            <label>
                Password:
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
            </label>
            <br />
            <label>
                Role:
                <select value={role} onChange={(e) => setRole(e.target.value)} required>
                    <option value="user">User</option>
                    <option value="admin">Admin</option>
                    <option value="systemAdmin">System Admin</option>
                </select>
            </label>
            <br />
            {errorMessage && <p className='error-message'>{errorMessage}</p>}
            <button type="submit">{isUpdateMode ? 'Update User' : 'Create User'}</button>
        </form>
    );
}

export default UserForm;
