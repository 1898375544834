import React, { createContext, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; 

const AuthContext = createContext();

export function AuthProvider({ children, onUnauthorized }) { 
    const [isAuthenticated, setIsAuthenticatedState] = useState(false);
    
    const setIsAuthenticated = (authStatus) => {
        setIsAuthenticatedState(authStatus);
        if (!authStatus && onUnauthorized) {
            onUnauthorized();
        }
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
}
