import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();
    const { setIsAuthenticated } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const loginResponse = await axios.post(process.env.REACT_APP_AUTH_LOGIN_ENDPOINT, { username, password }, { withCredentials: true });

            if (loginResponse.data.message === 'Logged in successfully.') {
                // Store the user role in local storage.
                localStorage.setItem('userRole', loginResponse.data.role);
                
                // Set isAuthenticated to true
                setIsAuthenticated(true);
                
                navigate('/dashboard');

            } else {
                setError('Failed to log in. Please try again.');
            }
        } catch (err) {
            console.error('Error during login request:', err);
            if (err.response && err.response.status === 401) {
                setError('Invalid username or password.');
            } else {
                setError('Failed to log in. Please try again.');
            }
        }
    };

    return (
        <section className="login-container">
            <h2>Login</h2>
            {error && <p className="error-message">{error}</p>}
            <form onSubmit={handleSubmit}>
                <div className="input-group">
                    <label>Username</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="input-group">
                    <label>Password</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Login</button>
            </form>
        </section>
    );
}

export default Login;
