import React, { useState, useEffect, useRef } from 'react';  // <-- Add useRef import
import axios from 'axios';
import { Button, TextField, Grid, Typography, Card, CardContent, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";



function AddSparePartForm(props) {
    const [customerName, setCustomerName] = useState('');
    const [partNumber, setPartNumber] = useState('');
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [selectedCertificates, setSelectedCertificates] = useState(null);
    const [feedbackMessage, setFeedbackMessage] = useState('');
        const modalRef = useRef(null);
        const [prevFocusedElement, setPrevFocusedElement] = useState(null);
        const [isUploading, setIsUploading] = useState(false);


    const showFeedback = (message) => {
        setFeedbackMessage(message);
        setTimeout(() => setFeedbackMessage(''), 5000);
    };
    const userRole = localStorage.getItem('userRole') || 'user';
const partNumberLabel = userRole === 'admin' ? 'AWB' : userRole === 'user' ? 'Invoice #' : 'Part Number';
    useEffect(() => {
                // Store the currently focused element when the modal is opened
        setPrevFocusedElement(document.activeElement);

        
        // Set the focus to the modal container when the component is mounted
        modalRef.current && modalRef.current.focus();
    }, []);  // Empty dependency array means this effect runs once when the component mounts

    const handleClose = () => {
        props.onClose();
        
        // After closing, return the focus to the previously focused element
        if (prevFocusedElement && typeof prevFocusedElement.focus === 'function') {
            prevFocusedElement.focus();
        }
    };



    async function handleSubmit(event) {
        event.preventDefault();
            setIsUploading(true);

        if ( !partNumber.trim()) {
            alert("the AWB /Invoice number is required.");
            return;
        }
            const type = userRole === 'admin' ? 'arrival' : 'dispatch';  // Set type based on userRole

        const currentDate = new Date().toISOString();
        const formData = new FormData();
        formData.append("customerName", customerName);
        formData.append("partNumber", partNumber);
            formData.append("type", type);  // Append type to formData
        formData.append("dateReceived", currentDate);
        if (selectedFiles) {
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append("images", selectedFiles[i]);
            }
        }
            // Handle certificate files if they are selected
    if (selectedCertificates && selectedCertificates.length > 0) {
        for (let i = 0; i < selectedCertificates.length; i++) {
            formData.append('certificates', selectedCertificates[i]);
        }
    }

        try {
            const response = await axios.post(process.env.REACT_APP_SPARE_PARTS_ADD_ENDPOINT, formData, { withCredentials: true });
            
            // Pass the newly added part data to the callback
            props.onAdd(response.data);


            showFeedback('Spare part successfully added!');
                    handleClose();
   
        } catch (error) {
            console.error('Error adding new spare part:', error);
            showFeedback('Error adding new spare part. Please try again.');
        } finally {
        setIsUploading(false);
    }
}
    

    return (
        <div aria-modal="true"             ref={modalRef}  // <-- Attach the ref to the modal container
            tabIndex="-1"   // <-- Ensure the div is focusable
 style={{ display: "block", position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1000 }}>
            <Card style={{ width: "90%", maxWidth: "800px", margin: "5% auto", padding: "20px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)" }}>
                <IconButton onClick={props.onClose} style={{ position: "absolute", right: "20px", top: "10px" }} aria-label="Close modal">
                    <CloseIcon />
                </IconButton>
                <CardContent>
                    <Typography variant="h5" id="addSparePartDialogTitle" tabIndex="-1">Add New Spare Part</Typography>

                    {feedbackMessage && <div style={{ margin: '20px 0', padding: '10px', backgroundColor: '#f8d7da', color: '#721c24', borderRadius: '5px', textAlign: 'center' }}>{feedbackMessage}</div>}

                    <form 
                        onSubmit={handleSubmit} 
                        style={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            gap: '20px',
                            backgroundColor: '#f5f5f5',
                            padding: '20px',
                            borderRadius: '10px',
                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <input
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={event => setSelectedFiles(event.target.files)}
                                />
                                <label>Images</label>
                            </Grid>
                            
                            <Grid item xs={12}>
    <input
        type="file"
        accept=".pdf"
        multiple
        onChange={event => setSelectedCertificates(event.target.files)}
    />
    <label>Certificates (PDFs)</label>
</Grid>

                                                        <Grid item xs={12}>
                                <TextField
                                id={partNumberLabel}
                                    label={partNumberLabel}
                                    variant="outlined"
                                    fullWidth
                                    value={partNumber}
                                    onChange={e => setPartNumber(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>

                                <TextField
                                    id="Customer name"
                                    label="Customer name"
                                    variant="outlined"
                                    fullWidth
                                    value={customerName}
                                    onChange={e => setCustomerName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button color="primary" variant="contained" type="submit" aria-label="Add" disabled={isUploading}>
    Add 
</Button>
{isUploading && <CircularProgress aria-label="Uploading..." aria-busy={true} role="status" />}

                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}

export default AddSparePartForm;
