import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UserForm from './UserForm';  // Importing the UserForm component

function Users() {
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState('create');
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    // Function to fetch users from the backend
    async function fetchUsers() {
        try {
            const response = await axios.get(process.env.REACT_APP_AUTH_GET_ALL_USERS_ENDPOINT);
            setUsers(response.data);
        } catch (error) {
            console.error("Error fetching users:", error);
            setErrorMessage("Error fetching users. Please refresh the page.");
        }
    }

    useEffect(() => {
        fetchUsers();
    }, []);

    async function deleteUser(userId) {
        try {
            await axios.delete(`${process.env.REACT_APP_AUTH_DELETE_USER_ENDPOINT}/${userId}`);
            fetchUsers();  // Refresh user list after deleting a user
        } catch (error) {
            console.error("Error deleting user:", error);
            setErrorMessage("Error deleting user. Please try again.");
        }
    }

    function openUpdateModal(userId) {
        setSelectedUserId(userId);
        setModalMode('update');
        setShowModal(true);
    }

    function openCreateModal() {
        setModalMode('create');
        setShowModal(true);
    }

    return (
        <section>
            <h1>Manage Users</h1>
            {errorMessage && <p className='error-message'>{errorMessage}</p>}
            <ul>
                {users.map(user => (
                    <li key={user.id}>
                        {user.username}
                        <button onClick={() => openUpdateModal(user.id)}>Update</button>
                        <button onClick={() => deleteUser(user.id)}>Delete</button>
                    </li>
                ))}
            </ul>
            <button onClick={openCreateModal}>Create New User</button>
            {showModal && (
                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.8)', color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <UserForm 
                        mode={modalMode} 
                        userId={selectedUserId} 
                        onUserUpdated={() => { 
                            setShowModal(false); 
                            fetchUsers();  // Refresh user list after updating a user
                        }} 
                        onUserCreated={() => { 
                            setShowModal(false); 
                            fetchUsers();  // Refresh user list after creating a user
                        }} 
                    />
                    <button onClick={() => setShowModal(false)} style={{ position: 'absolute', top: 10, left: 10 }}>Back</button>
                </div>
            )}
        </section>
    );
}



export default Users;
