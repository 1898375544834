import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import React, { useState, useEffect, useRef } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import SparePartDetails from './SparePartDetails';
import AddSparePartForm from './AddSparePartForm';
import { Button, Card, CardContent, Typography, CardActions, TextField, AppBar, Toolbar, InputBase, IconButton } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DashboardIcon from '@material-ui/icons/Dashboard';
import _ from 'lodash'; // Import lodash for throttling
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import stringSimilarity from 'string-similarity';
import { useSparePartsState, useSparePartsDispatch } from './sparePartsContext';
// ... Other imports
function SparePartsDashboard() {
// State for managing parts and display
  const state = useSparePartsState();
  const dispatch = useSparePartsDispatch();
  const [allParts, setAllParts] = useState([]);
const [displayParts, setDisplayParts] = useState([]);
const [spareParts, setSpareParts] = useState([]);
const [searchResults, setSearchResults] = useState([]);
const [startDate, setStartDate] = useState(null);
const [endDate, setEndDate] = useState(null);
    const [showDatePickers, setShowDatePickers] = useState(false);

// State for UI modal/display management
const [showAddModal, setShowAddModal] = useState(false);
const [showDetailsModal, setShowDetailsModal] = useState(false);
const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

// State for managing selected and to-be-deleted parts
const [selectedPart, setSelectedPart] = useState(null);
const [partToDelete, setPartToDelete] = useState(null);

// State for search and page management
const [searchTerm, setSearchTerm] = useState('');
const [page, setPage] = useState(1);

// State for user role and authorization
const [userRole, setUserRole] = useState(localStorage.getItem('userRole') || '');

// State for error handling and display
const [errorMessage, setErrorMessage] = useState(null);

// State for loading management
const [isLoading, setIsLoading] = useState(false);
//check user role to filtor
const shouldDisplayPart = (part, userRole) => {
    switch(part.type) {
        case 'all': 
            return true;
        case 'arrival': 
            return userRole === 'admin' || userRole === 'systemAdmin';
        case 'dispatch': 
            return userRole === 'user' || userRole === 'systemAdmin';
        default: 
            return true;
    }
};

const determinePartType = (userRole) => {
    switch (userRole) {
        case 'admin':
            return 'arrival';
        case 'user':
            return 'dispatch';
        case 'systemAdmin':
            return null;  // systemAdmin gets everything
        default:
            return null;
    }
};

// Debounce function for search term input
const debouncedSearchTerm = useRef(_.debounce(q => {
    console.log('Debounced function called with:', q);  // Log the debounced input value
    setSearchTerm(q);
}, 250)).current;
useEffect(() => {
    handleSearch(searchTerm);
}, [searchTerm]);


// ---------------------
// Scroll Handlers
// ---------------------
const handleScroll = _.throttle((event) => {
    if (!event.currentTarget) return;

    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    
    if (scrollHeight - scrollTop <= clientHeight + 100 && !isLoading) {
        setPage(prevPage => prevPage + 1);
    }
}, 250);

// ---------------------
// Data Fetching Handlers
// ---------------------
useEffect(() => {
    const fetchSpareParts = async () => {
        dispatch({ type: 'FETCH_START' });

        try {
            const partType = determinePartType(userRole); 
// Determine the endpoint based on the user role
        let endpoint;
        if (userRole === 'systemAdmin') {
            endpoint = `${process.env.REACT_APP_SPARE_PARTS_GET_ALL_ADMIN_ENDPOINT}?page=${page}`; // Assuming you have this env variable for the admin-specific endpoint
        } else {
            endpoint = `${process.env.REACT_APP_SPARE_PARTS_GET_ALL_ENDPOINT}?page=${page}`;
            if (partType) {
                endpoint += `&partType=${partType}`;
            }
        }

            const response = await axios.get(endpoint, { withCredentials: true });

            // If it's the first page, reset allParts and displayParts
            if (page === 1) {
                setAllParts(response.data.data);
                setDisplayParts(response.data.data);
            } else {
                // Ensure only unique parts are appended to allParts
                const existingAllPartNumbers = new Set(allParts.map(part => part.partNumber));
                const uniqueAllFetchedParts = response.data.data.filter(part => !existingAllPartNumbers.has(part.partNumber));
                setAllParts(prevParts => [...prevParts, ...uniqueAllFetchedParts]);

                // Ensure only unique parts are appended to displayParts
                const displayedPartNumbers = new Set(displayParts.map(part => part.partNumber));
                const uniqueDisplayFetchedParts = response.data.data.filter(part => !displayedPartNumbers.has(part.partNumber));
                setDisplayParts(prevParts => [...prevParts, ...uniqueDisplayFetchedParts]);
            }
        } catch (error) {
            dispatch({ type: 'FETCH_FAILURE', payload: error.message });
        }
    };
    fetchSpareParts();
}, [dispatch, page, userRole]);

// ---------------------
// Action Handlers
// ---------------------
    const handleFilterClick = () => {
        setShowDatePickers(!showDatePickers);
    };
    
const clearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setShowDatePickers(false);
    setDisplayParts(allParts);  // Reset to the original list
}

const handleAddNewPart = (newPart) => {
    setShowAddModal(false);
    setDisplayParts(prevParts => [newPart, ...prevParts]);
};

const handleDownload = (partNumber) => {
    const url = `${process.env.REACT_APP_SPARE_PARTS_DOWNLOAD_PHOTOS_ZIP_ENDPOINT.replace(':partNumber', partNumber)}`;
    window.open(url, '_blank');
};

const handleDelete = async () => {
    if (!partToDelete) return;
    
    try {
        await axios.delete(`${process.env.REACT_APP_SPARE_PARTS_DELETE_BY_NUMBER_ENDPOINT}?id=${partToDelete._id}`, {
            withCredentials: true
        });
        setDisplayParts(updatedParts => updatedParts.filter(part => part.partNumber !== partToDelete.partNumber));
    } catch (error) {
        console.error("Error deleting spare part:", error);
    }

    setPartToDelete(null);
    setDeleteDialogOpen(false);
};

const openDeleteDialog = (part) => {
    setPartToDelete(part);
    setDeleteDialogOpen(true);
};

const handleShowDetails = (part) => {
    setSelectedPart(part);
    setShowDetailsModal(true);
};

const handleFilter = async () => {
    try {
        const queryParams = new URLSearchParams();

        if (startDate) queryParams.append('startDate', startDate.toISOString());
        if (endDate) queryParams.append('endDate', endDate.toISOString());

        const endpoint = `${process.env.REACT_APP_SPARE_PARTS_FILTER_ENDPOINT}?${queryParams.toString()}`;
        
        // Making the request with credentials
        const response = await axios.get(endpoint, { withCredentials: true });
        
        if (response.status !== 200) {
            throw new Error('Failed to fetch filtered data');
        }

        setDisplayParts(response.data);
        setPage(1);
    } catch (error) {
        console.error("Error filtering spare parts:", error);
        setErrorMessage("Error filtering spare parts. Please try again.");
    }
};

const handleSearch = async (query) => {

    
    if (query.length >= 2) {  
        setIsLoading(true);
        try {
            const limit = 35; // Set limit to 35
            const endpoint = `${process.env.REACT_APP_SPARE_PARTS_SEARCH_ENDPOINT}?term=${query}&limit=${limit}`;
            const response = await axios.get(endpoint, { withCredentials: true });
            
            let fetchedParts = response.data.data;
            console.log("Fetched parts for search:", fetchedParts);

            if (fetchedParts && fetchedParts.length > 0) { 
                fetchedParts = fetchedParts.sort((a, b) => {
                    const aSimilarity = stringSimilarity.compareTwoStrings(a.partNumber, query);
                    const bSimilarity = stringSimilarity.compareTwoStrings(b.partNumber, query);
                    return bSimilarity - aSimilarity;
                });
            }
                        setDisplayParts([]);
            setDisplayParts(fetchedParts); // Set the search results directly

        } catch (error) {
            console.error("Error fetching spare parts:", error);
            setErrorMessage("Error fetching spare parts. Please refresh the page.");
        } finally {
            setIsLoading(false);
        }
    } else {
        setPage(1);
        setDisplayParts(allParts);  // Reset to all parts when the search term is too short
    }
};



    return (
        <div>
            <AppBar position="static">
                <Toolbar>
<InputBase 
    placeholder="Search Spare Parts…"
    startAdornment={<SearchIcon />}
    value={searchTerm}
    onChange={(e) => debouncedSearchTerm(e.target.value)}  
    style={{ marginRight: '20px', flex: 1 }}
/>
<Button 
    variant="contained" 
    onClick={handleFilterClick} 
    style={{ marginRight: '10px' }}
>
    Filter
</Button>
{showDatePickers && (
    <>
        <label>Start Date</label>
        <DatePicker
            selected={startDate}
            onChange={setStartDate}
            dateFormat="dd/MM/yyyy"
            className="some-input-class-if-needed"
        />
        
        <label>End Date</label>
        <DatePicker
            selected={endDate}
            onChange={setEndDate}
            dateFormat="dd/MM/yyyy"
            className="some-input-class-if-needed"
        />

        <Button 
            variant="contained" 
            color="primary"
            onClick={handleFilter} 
            style={{ marginRight: '10px' }}
        >
            Apply Filter
        </Button>

        <Button 
            variant="contained" 
            color="secondary"
            onClick={clearFilters} 
            style={{ marginRight: '10px' }}
        >
            Clear Filter
        </Button>
    </>
)}

                    <Button 
                        variant="contained" 
                        startIcon={<AddIcon />} 
                        color="primary"
                        onClick={() => setShowAddModal(true)}
                    >
                        Add Record
                    </Button>
                </Toolbar>
            </AppBar>
            <div onScroll={handleScroll} style={{ height: '80vh', overflowY: 'scroll' }}>
            {isLoading && (
    <div style={{textAlign: 'center', padding: '20px'}}>
        <CircularProgress />
    </div>
)}
{errorMessage && (
    <div style={{color: 'red', textAlign: 'center', padding: '20px'}}>
        {errorMessage}
    </div>
)}


{
    displayParts.filter(part => shouldDisplayPart(part, userRole)).map(part => {
        const uniqueKey = `${part.dateReceived}-${part.partNumber}`;
        return (
                                <Card key={uniqueKey} style={{ marginTop: '20px' }}>
                            <CardContent>
                                <Typography variant="h6">{part.partNumber}</Typography>
                                <Typography variant="body1">{part.customerName}</Typography>
                            </CardContent>
                            <CardActions>
                                <Button onClick={() => handleShowDetails(part)}>View Details</Button>
<Button onClick={() => openDeleteDialog(part)}>Delete</Button>

    <Button onClick={() => handleDownload(part.partNumber)}>Download</Button> {/* Updated onClick handler */}

                            </CardActions>
                        </Card>
                        );
    })
            }
            {showAddModal && ( 
                <AddSparePartForm  
                    onClose={() => setShowAddModal(false)}
                    onAdd={handleAddNewPart}
                />
            )}
            {showDetailsModal && (
                <SparePartDetails 
                    show={showDetailsModal}
                    handleClose={() => setShowDetailsModal(false)}
                    partDetails={selectedPart}
                />
            )}
            
            
        </div>
        <Dialog
    open={deleteDialogOpen}
    onClose={() => setDeleteDialogOpen(false)}
> 
    <DialogTitle>Confirm Deletion</DialogTitle> 
    <DialogContent>
        <DialogContentText>
            Are you sure you want to delete part number {partToDelete ? partToDelete.partNumber : ''}?
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
        </Button>
        <Button onClick={handleDelete} color="primary">
            Confirm
        </Button>
    </DialogActions>
</Dialog>

        </div>
    );
}

export default SparePartsDashboard;
