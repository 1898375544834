import "react-datepicker/dist/react-datepicker.css";

import { SparePartsProvider } from './components/sparepart/sparePartsContext';  // Update the path accordingly
import axios from 'axios';
import { BeatLoader } from 'react-spinners';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import './App.css';
import Header from './components/header/Header';
import Login from './components/login/Login';
import SparePartsDashboard from './components/sparepart/SparePartsDashboard';
import Users from './components/users/Users';
import { AuthProvider, useAuth } from './AuthContext';

function AppContent() {
    const navigate = useNavigate();
    const { isAuthenticated, setIsAuthenticated } = useAuth();
    const [isLoading, setIsLoading] = useState(true);

    async function apiCallToVerifyToken() {
        try {
            const response = await axios.get(process.env.REACT_APP_AUTH_VERIFY_TOKEN_ENDPOINT, { withCredentials: true });
            return response.data; // Just return the data directly.
        } catch (error) {
            console.error('Error verifying token:', error);
            return { authenticated: false };
        }
    }

    useEffect(() => {
        apiCallToVerifyToken().then(response => {
            setIsAuthenticated(response.authenticated || false);
            setIsLoading(false);
        });
    }, [setIsAuthenticated]);

    if (isLoading) {
        return <BeatLoader color={'#123abc'} loading={isLoading} size={15} />;
    }

    return (
        <div className="App">
            <Header />
            <Routes>
                <Route path="/" element={isAuthenticated ? 
                  <SparePartsProvider><SparePartsDashboard /></SparePartsProvider> : 
                  <Login />} 
                />
                <Route path="/dashboard" element={isAuthenticated ? 
                  <SparePartsProvider><SparePartsDashboard /></SparePartsProvider> : 
                  <Login />} 
                />
                <Route path="/users" element={isAuthenticated ? <Users /> : <Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<div>Not Found</div>} />
            </Routes>
        </div>
    );
}
function App() {
    return (
        <AuthProvider>
            <Router>
                <AppContent />
            </Router>
        </AuthProvider>
    );
}

export default App;
