import axios from 'axios';
import React, { useEffect, useRef, useState } from "react";
import { Card, CardContent, Typography, Button, Grid, IconButton, Modal, Box } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const userRole = localStorage.getItem('userRole');

function HumanReadableDate({ dateString }) {
    const date = new Date(dateString);
    const formattedDate = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    return <span>{formattedDate}</span>;
}

function SparePartDetails({ show, handleClose, partDetails }) {
    const modalStyles = {
        display: show ? "block" : "none",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1000
    };

    const cardStyles = {
        width: "90%",
        maxWidth: "800px",
            maxHeight: " 90vh", // Example value, adjust as needed
    overflowY: "auto",
        margin: "5% auto",
        padding: "20px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)"
    };

    const [selectedCertificates, setSelectedCertificates] = useState(null);
    const [uploadCompleted, setUploadCompleted] = useState(false);
    const [certificates, setCertificates] = useState([]);
    const fileInputRef = useRef(null);
    const modalRef = useRef(null);
    const closeButtonRef = useRef(null);
    const [isLoadingCertificates, setIsLoadingCertificates] = useState(true);


    const handleCertificateSelection = (event) => {
        setSelectedCertificates(event.target.files);
    };

    const handleAddCertificatesClick = () => {
        fileInputRef.current.click();
        setUploadCompleted(false);
    };
    
    const handleCloseModal = () => {
        setIsImageModalOpen(false); // Close the child modal
        handleClose();              // Call the original handleClose function passed as a prop
    };
    const closeImage = () => {
        setIsImageModalOpen(false);
    };

    const handleUploadCertificates = async () => {
        if (selectedCertificates) {
            try {
                const formData = new FormData();
                [...selectedCertificates].forEach(file => formData.append("certificates", file));

                const baseUrl = process.env.REACT_APP_SPARE_PARTS_ADD_CERTIFICATES_ENDPOINT;
                const endpoint = `${baseUrl}${partDetails._id}/appendCertificates`;

                await axios.post(endpoint, formData, { withCredentials: true });
                setUploadCompleted(true);
                alert("Certificates uploaded successfully!");
                fetchCertificates();
            } catch (error) {
                console.error("Error uploading certificates:", error);
            }
        }
    };

    const fetchCertificates = async () => {
        try {
            const baseUrl = process.env.REACT_APP_SPARE_PARTS_GET_BY_NUMBER_ENDPOINT;
            const endpoint = `${baseUrl}${partDetails._id}/certificates`;
            const response = await axios.get(endpoint, { withCredentials: true });
            //console.log("this is the responce:", response.data);
            setCertificates(response.data.certificates);
            //console.log(certificates);
            setIsLoadingCertificates(false)
        } catch (error) {
            console.error("Error fetching certificates:", error);
        }
    };

    useEffect(() => {
        if (show) {
            fetchCertificates();
        }
    }, [show]);

    useEffect(() => {
        if (show) {
            
            const prevActiveElement = document.activeElement;
            modalRef.current.focus();

            const handleOutsideClick = (event) => {
                if (!modalRef.current.contains(event.target)) {
                    handleCloseModal();
                }
            };

            const handleKeyDown = (event) => {
                if (event.key === 'Escape') {
                    handleCloseModal();
                }
            };

            document.addEventListener('mousedown', handleOutsideClick);
            document.addEventListener('keydown', handleKeyDown);

            return () => {
                document.removeEventListener('mousedown', handleOutsideClick);
                document.removeEventListener('keydown', handleKeyDown);
                if (prevActiveElement) {
                    prevActiveElement.focus();
                }
            };
        }
    }, [show, handleClose]);
    
        // New state for image modal
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    // Open image modal with selected image
    const openImageModal = (imageSrc) => {
        setSelectedImage(imageSrc);
        setIsImageModalOpen(true);
    };

    // Close image modal
    const closeImageModal = () => {
        setIsImageModalOpen(false);
    };



return (
    <section 
        role="dialog"
        aria-modal="true"
        ref={modalRef}
        tabIndex="-1"
        style={modalStyles}
    >
        <Card style={cardStyles}>
            <IconButton 
                ref={closeButtonRef} 
                onClick={handleClose} 
                style={{ position: "absolute", right: "20px", top: "10px" }} 
                aria-label="Close modal"
            >
                <CloseIcon />
            </IconButton>

            <CardContent>
                <Typography variant="h5">Part Details</Typography>
                <Typography variant="body1">Customer Name: {partDetails.customerName}</Typography>
                <Typography variant="body1">
                    {userRole === 'admin' ? 'AWB' : 'Invoice #'}: {partDetails.partNumber}
                </Typography>
                <Typography variant="body1">
                    Date Images Uploaded: <HumanReadableDate dateString={partDetails.dateReceived} />
                </Typography>

<Grid container spacing={2} style={{ marginTop: '15px' }}>
    <Grid item xs={12}>
        <Typography variant="body1">Images:</Typography>
    </Grid>
    {partDetails.images && partDetails.images.length > 0 ? 
        partDetails.images.map((img, index) => (
            <Grid item xs={4} key={index} onClick={() => openImageModal(img)}>
                <img
                    src={img}
                    alt={`Thumbnail ${index + 1}`}
                    style={{ width: '100%', borderRadius: '5px', cursor: 'pointer' }}
                />
            </Grid>
        )) : (
            <Grid item xs={12}>
                <Typography variant="body2">No images available for this part.</Typography>
            </Grid>
        )
    }
</Grid>


                <Grid container spacing={2} style={{ marginTop: '15px' }}>
                    <Grid item xs={12}>
                        <Typography variant="body1">Certificates:</Typography>
                    </Grid>
                    {certificates && certificates.length > 0 ? (


                        certificates.map((cert, index) => (
                            <Grid item xs={12} key={index}>
                                <a href={cert} target="_blank" rel="noopener noreferrer">
                                    Certificate {index + 1}
                                </a>
                            </Grid>
                        ))
                    ) : (
                        <Grid item xs={12}>
                            <Typography variant="body2">No certificates available for this part.</Typography>
                        </Grid>
                    )}
                </Grid>

                <Grid container spacing={2} style={{ marginTop: '15px' }}>
                    <Grid item xs={12}>
                        <Button variant="outlined" onClick={handleAddCertificatesClick}>
                            Add Certificates
                        </Button>
                    </Grid>
                    {!uploadCompleted && selectedCertificates && (
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={handleUploadCertificates}>
                                Upload
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <input
                            type="file"
                            accept=".pdf"
                            multiple
                            onChange={handleCertificateSelection}
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        
                    {/* Image Modal */}
            <Modal
                open={isImageModalOpen}
                onClose={closeImage}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box style={{ outline: 'none', background: 'white', padding: '20px', borderRadius: '5px' }}>
                       {/* Close Button for the Image Modal */}
        <IconButton
            onClick={closeImage}
            style={{ position: 'absolute', right: '10px', top: '10px' }}
            aria-label="Close image"
        >
            <CloseIcon />
        </IconButton>
                    <img src={selectedImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
                </Box>
                            </Modal>
    </section>
);
}
export default SparePartDetails;
